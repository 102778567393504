<template>
  <div>
    <v-card>
      <v-toolbar :height="$vuetify.breakpoint.smAndUp ? 60 : 120" extended>
        <v-card-title class="break-word pl-0"
          >List of active remote controls for ASPX playlists</v-card-title
        >
        <v-spacer></v-spacer>
        <template v-slot:extension>
          <v-tabs v-model="tab" class="ml-0">
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab
              v-for="tab in tabItems"
              :key="tab.id"
              :to="tab.route"
              :data-testid="'tab-' + tab.name"
            >
              {{ tab.name }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-card-text data-testid="container-remote-controls">
        <v-tabs-items v-model="tab" touchless>
          <router-view />
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLog: {},
      tabItems: [
        { id: "1", name: "active", route: "/engines/remote-control/active" },
        { id: "2", name: "history", route: "/engines/remote-control/history" },
      ],
      tab: null,
    }
  },

  mounted: function () {
    if (!this.$can("rc.playlist", "ws_rc")) {
      if (!this.$can("read", "Superadmin"))
        this.$router.push({ name: "home" }).catch((err) => {})
    }
  },
}
</script>

<style lang="scss" scoped>
.rc-tooltip-last-log {
  white-space: pre-line;

  .cell-actions {
    width: 224px;
    white-space: nowrap;
  }

  .cell-name {
    width: 240px;
  }
}
</style>
